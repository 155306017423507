import React from "react";
import {
  Box,
  Button,
  Input,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import PreviewIcon from "@mui/icons-material/Preview";
import { Link } from "react-router-dom";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";

const DucImages = ({ ducImages, updateSrfDetails, openErrorModal }) => {
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const data = new FormData();
    data.append("fileToUpload", file);

    axiosWithToken
      .post(BASE_URL + "fileUpload/fileUpload.php", data)
      .then((res) => {
        if (res.status === 200) {
          updateSrfDetails({
            ducImages: [...ducImages, res.data],
          });
          toast.success("File Uploaded Successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(err.message, "handleFileUpload", "ducImages")
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  };

  const handleDelete = (urlToDelete) => {
    const updatedList = ducImages.filter((url) => url !== urlToDelete);
    updateSrfDetails({ ducImages: updatedList });
  };

  return (
    <div>
      <h4 style={{ textAlign: "left", marginBottom: "20px" }}>
      Duc Images
      </h4>
      <div style={{ textAlign: "left", marginBottom: "20px" }}>
        <Input
          id="file-upload-input1"
          type="file"
          style={{ display: "none" }}
          onChange={(e) => handleFileUpload(e)}
        />
        <label htmlFor="file-upload-input1">
          <Button variant="contained" component="span" size="small">
            Upload Image
          </Button>
        </label>
      </div>
      {ducImages && ducImages.length > 0 && (
        <TableContainer>
          <Table style={{ width: "100%" }}>
            <TableHead>
              <TableRow
                sx={{
                  fontWeight: "bold",
                  backgroundColor: "#ddd",
                  textAlign: "center",
                }}
              >
                <TableCell>Sr.No</TableCell>
                <TableCell>File Name</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ducImages?.map((url, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{url?.split("__")[1]}</TableCell>
                  <TableCell>
                    <Button component={Link} to={url} target="_blank">
                      <PreviewIcon />
                    </Button>
                    <DeleteIcon
                      style={{ color: "#dc3545" }}
                      onClick={(e) => {
                        const confirmation = window.confirm(
                          "Are you sure you want to delete this"
                        );
                        if (confirmation) {
                          handleDelete(url);
                        }
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default DucImages;
